<template>
    <div> 
        <div class="page-wrapper ">
            <div class="auth-bg">
                <div class="authentication-box"> 
                    <div class="text-center "><img src="@/assets/images/logo-ecart.svg" class=" img-100 " alt=""></div>
                    <div class="card mt-4 p-4">
                        <h4 class="text-center text-uppercase " v-text=" $t('recoveryPassword.title') "></h4>
                        <h6 class="text-center my-4 " v-text=" $t('recoveryPassword.message2') "></h6>
                        <b-alert variant="danger" dismissible fade :show="showAlert" >
                            <i class=" fa fa-warning "></i> <span v-text=" $t('recoveryPassword.alertRecovery') "> </span>
                        </b-alert>

                        <v-observer tag="form" class="form-rounded-inputs" ref="formRecoveryPassword" @submit.prevent="fnApiUpdatePassword()"  >
                            <div class="row">

                                <div class="col-12">
                                    <v-validation rules="required|min:8" v-slot="{ errors }" :name=" $t('recoveryPassword.newPassword') ">
                                        <div class="form-group">
                                            <label for="" v-text=" $t('recoveryPassword.newPassword') " ></label>
                                            <div class="input-group mb-3">
                                                <input type="password" min="1" class="form-control" @focus=" showAlert = false"
                                                v-model=" recovery.password" :class=" { 'is-invalid':errors.length > 0 } ">
                                                <div class="invalid-feedback" v-if="errors.length > 0">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </div>
                                    </v-validation> 
                                </div>

                                <div class="col-12">
                                    <v-validation rules="required|min:8" v-slot="{ errors }" :name=" $t('recoveryPassword.repeatPassword') ">
                                        <div class="form-group">
                                            <label for="" v-text=" $t('recoveryPassword.repeatPassword') " ></label>
                                            <div class="input-group mb-3">
                                                <input type="password" min="1" class="form-control" @focus=" showAlert = false"
                                                 v-model=" recovery.password_confirmation " :class=" { 'is-invalid':errors.length > 0 } ">
                                                <div class="invalid-feedback" v-if="errors.length > 0">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </div>
                                    </v-validation>
                                    <b-alert variant="danger" fade :show="recovery.password != recovery.password_confirmation" >
                                        <i class=" fa fa-warning "></i> <span v-text=" $t('recoveryPassword.alertPasswords') "></span>
                                    </b-alert>
                                </div>

                                <div class="col-auto ml-auto">
                                    <button class=" btn btn-primary " :disabled="sendingData || recovery.password != recovery.password_confirmation || (recovery.password == null || recovery.password_confirmation == null)" >
                                        <span class="text-capitalize"  v-text="$t('general.button.send')" v-if=" !sendingData "  ></span> 
                                        <i class="fa fa-spinner fa-spin " v-else ></i>
                                    </button> 
                                </div>
                            </div>
                        </v-observer> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return (  {
                sendingData:false,
                showAlert:false,
                recovery:{
                    recovery: null,
                    password: null,
                    password_confirmation: null,
                }, 
            } )
        },
        methods:{
            async fnApiUpdatePassword(){
                if (await this.$refs['formRecoveryPassword'].validate()) { 
                    this.sendingData = true;
                    await axios.put('me/recovery' , this.recovery ).then( ( response )=>{
                        this.$router.push('/login');
                    } ).catch( (error)=>{ 
                        this.showAlert = true;
                     } );
                    this.sendingData = false;
                }
            }
        },
        mounted() {
            this.recovery.recovery = this.$route.params.id;
        }
    }
</script> 